<template>
    <div>
        <div id="checkout">
            <!-- Checkout will insert the payment form here -->
        </div>
    </div>
</template>
<script>
import { getCaseApplication } from '../api/ipsye';
import {
    getUserId
} from '../utils/store'
export default {
    data() {
        return {
            user_id: "",
            amount: "",
            currency: "",
        };
    },
    mounted() {
        let application_id = this.$route.query.application_id
        getCaseApplication(application_id).then((res) => {
            let result = res.data.data
            let amount = result.service_fee + result.total_price
            if (this.checkTestUser()) {
                amount = 3.5
            }
            let line_items = [{
                price_data: {
                    currency: "usd",
                    product_data: {
                        name: 'IPsyE Subscription',
                    },
                    unit_amount: amount * 100,
                },
                quantity: 1,
            }]
            this.pay(application_id, line_items);
        });
    },
    methods: {

        checkTestUser(){
            let user_id = getUserId()
            return user_id === "61cd6856a4640b946f8b456f" || user_id === "64b0f34ca4640b167b8b45b2" || user_id === "654de577a4640bc4308b45ee" || user_id === '66e4284b94c784bc388b4575';
        },

        pay(application_id, line_items) {
            // Initialize Stripe.js
            const stripe = Stripe(
                "pk_live_51P128qP6BucL4HOz2SJY6gWLATlcK5rGdNTnePD780jhsy6ZLf7ZxIHxRE1oBLk9pchjvIoqHUwnPPGB10DmtGOO00yikhqAr0"
            );

            initialize();
            // Fetch Checkout Session and retrieve the client secret
            async function initialize() {
                const fetchClientSecret = async () => {
                    const response = await fetch(
                        "https://web-backend.reachable-edu.com/Stripe/IPsyoEmbedPay.php",
                        {
                            method: "POST",
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify({
                                application_id: application_id,
                                line_items: line_items,
                            }),
                        }
                    );
                    let result = await response.json();
                    const { clientSecret } = result;
                    return clientSecret;
                };

                // Initialize Checkout
                const checkout = await stripe.initEmbeddedCheckout({
                    fetchClientSecret,
                });

                // Mount Checkout
                checkout.mount("#checkout");
            }
        },
    },
};
</script>

<style></style>